/**
 * Footer Scoped Scripts migrated from Astro Layout
 * https://github.com/Uscreen-video/uscreen_static/blob/4842874e6c5de0b2ed9f832404779adad2b634c0/src/components/footer/inner/FooterAccordion.astro#L37C1-L66
 */

export default function() {
  const headers = document.querySelectorAll('[data-footer-accordion-header]');

  // Add event listeners to fire confetti when a button is clicked.
  headers.forEach((button) => {
    button.addEventListener('click', () => {
      const parent = button.closest('[data-footer-accordion]')
      const content = parent.querySelector('[data-footer-accordion-content]')
      if (parent.classList.contains('accordion-opened')) {
        parent.classList.remove('accordion-opened')
        content.animate([
          { height: `${content.scrollHeight}px` },
          { height: '0px' },
        ], {
          duration: 300,
          iterations: 1,
        });
      } else {
        parent.classList.add('accordion-opened')
        content.animate([
          { height: '0px' },
          { height: `${content.scrollHeight}px` },
        ], {
          duration: 300,
          iterations: 1,
        });
      }
    });
  });
}
