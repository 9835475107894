/**
 * Header Scoped Scripts migrated from Astro Layout
 * https://github.com/Uscreen-video/uscreen_static/blob/e6d2a5e8739a16d0f7e105ae16b0fb2b402fd69f/src/components/Header.astro#L293-L402
 */

export default function() {
  function closeDropdownHeaderMenu(item) {
    const parent = item.closest('.header-menu-dropdown')
    parent.classList.remove('opened')
    parent.querySelector('.header-menu-dropdown-content').removeAttribute('style')
    document.body.classList.remove('header-menu-dropdown-opened')
  }
  function toggleDropdownHeaderMenu(e) {
    const parent = e.currentTarget.closest('.header-menu-dropdown')
    const openedSibling = parent.parentNode.querySelector('.header-menu-dropdown.opened')
    if (parent.classList.contains('opened')) {
      closeDropdownHeaderMenu(e.currentTarget)
    } else {
      if (openedSibling) {
        openedSibling.classList.remove('opened')
        openedSibling.querySelector('.header-menu-dropdown-content').removeAttribute('style')
      }
      parent.classList.add('opened')
      parent.querySelector('.header-menu-dropdown-content').style.height = `${parent.querySelector('.header-menu-dropdown-content-inner').scrollHeight}px`
      document.body.classList.add('header-menu-dropdown-opened')
    }
  }
  function clickByHeaderOverlay() {
    const openedItem = document.querySelector('.header-menu-dropdown.opened')
    if (openedItem) {
      closeDropdownHeaderMenu(openedItem)
    }
  }
  function toggleHeaderMenu() {
    if (document.body.classList.contains('header-menu-opened')) {
      document.body.classList.remove('header-menu-opened')
    } else {
      document.body.classList.add('header-menu-opened')
    }
  }
  function resizeDropdownItems() {
    if (!document.querySelector('.header-menu-dropdown-content')) return
    document.querySelectorAll('.header-menu-dropdown-content').forEach(item => {
      if (item.style.height) {
        item.style.height = `${item.querySelector('.header-menu-dropdown-content-inner').scrollHeight}px`
      }
    })
  }
  function listenerForHeader() {
    if (!document.querySelector('.header-menu-bar') || !document.querySelector('.header-menu-dropdown-header')) return
    // click by menu bar (mobile)
    document.querySelector('.header-menu-bar').addEventListener('click', toggleHeaderMenu)
    // click by header menu item opens dropdown content
    document.querySelectorAll('.header-menu-dropdown-header').forEach((item) => {
      item.addEventListener('click', toggleDropdownHeaderMenu)
    })
    // recalculate height for dropdown content
    window.addEventListener('resize', resizeDropdownItems,{passive: true})

    // click by dark overlay to close menu
    document.querySelector('.header-overlay').addEventListener('click', clickByHeaderOverlay)
  }
  document.addEventListener('DOMContentLoaded', listenerForHeader);

  function isScrollY() {
    return window.scrollY >= 10
  }

  function toggleHeaderSmall() {
    const header = document.querySelector('.header')
    if (isScrollY()) {
      if (header.classList.contains('header-small')) return
      header.classList.add('header-small')
    } else {
      header.classList.remove('header-small')
    }
  }
  document.addEventListener('scroll', () => toggleHeaderSmall(), { passive: true });
}
